import React, { Component } from 'react';
import { Rate, Radio, Checkbox } from 'antd';

export default class RateComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rateValue: [],
      checkSubPerguntas: [],
    };
  }

  componentDidMount() {
    if (this.props.arrResponse) {
      const response = this.props.arrResponse.find(
        resposta => resposta.idPergunta == this.props.item.id
      );

      if (response) {
        this.setState({
          checkSubPerguntas: [response.idSubPergunta],
        });
      }
    }
  }

  handleChange = (value, index) => {
    const { rateValue } = this.state;
    rateValue[index] = value;
    this.setState({ rateValue });

    if (value <= 3) {
      this.setState({ subPerguntas: this.props.subPergunta });
    } else {
      this.setState({ subPerguntas: 5 });
    }
    this.props.changeRate(value, this.props.item, this.props.index);
  };

  onChangeCheck(checkedValues, item) {
    this.props.changeSubAsk(checkedValues.target.value, item);
  }

  onChangeRadio = e => {
    const selectedValue = e.target.value;
    this.setState({
      checkSubPerguntas: [selectedValue],
    });
  };

  currentValue() {
    let currentResponse = this.props.arrResponse
      .filter(item => item.idPergunta === this.props.item.id)
      .shift();
    if (currentResponse && currentResponse.valorInteiro) {
      return parseInt(currentResponse.valorInteiro);
    }
    return 0;
  }

  render() {
    return (
      <span>
        {!this.props.multiSelect ? (
          <>
            <Rate
              onChange={this.handleChange}
              style={{ fontSize: 40, color: '#00a1ed' }}
              value={
                this.props.arrResponse && this.props.arrResponse.length > 0
                  ? this.currentValue()
                  : 0
              }
            />
            {this.state.rateValue[this.props.index] <= 3 ? (
              <>
                <h4>O que houve?</h4>
                {this.props.item.subPergunta.map(item => {
                  return (
                    <Checkbox
                      key={item.id}
                      onChange={checkedValues =>
                        this.onChangeCheck(checkedValues, item)
                      }
                      value={item.id}
                    >
                      {item.descricao}
                    </Checkbox>
                  );
                })}
              </>
            ) : null}
          </>
        ) : (
          <Radio.Group
            onChange={this.onChangeRadio}
            value={this.state.checkSubPerguntas[0]}
          >
            {this.props.item.subPergunta.map(item => {
              return (
                <Radio
                  key={item.id}
                  value={item.id}
                  onChange={checkedValues =>
                    this.onChangeCheck(checkedValues, item)
                  }
                >
                  {item.descricao}
                </Radio>
              );
            })}
          </Radio.Group>
        )}
      </span>
    );
  }
}
