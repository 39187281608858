import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Table,
  Card,
  Switch,
  DatePicker,
  Button,
  List,
  Collapse,
  Badge,
  message,
} from 'antd';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserData, setChecketedRow } from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import Service from './Service';
import './styles.css';

const { Content } = Layout;
const { Panel } = Collapse;

const NPS = 'nps';
const ELOGIOS = 'elogio';
const CRITICA = 'critica';
const DETRATOR = 'detrator';
const PROMOTOR = 'promotor';
const NEUTRO = 'neutro';
const ID_PERGUNTA_NPS = 85;
const ID_PERGUNTA_ELOGIO = 86;
const ID_PERGUNTA_CRITICA = 87;
const DETRATOR_DESC = 'Detrator';
const PROMOTOR_DESC = 'Promotor';
const NEUTRO_DESC = 'Neutro';
const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_INITAL = moment(
  new Date(new Date().setDate(new Date().getDate() - 30))
).format('YYYY-MM-DD');
const DATE_FINAL = moment(
  new Date(new Date().setDate(new Date().getDate()))
).format('YYYY-MM-DD');

class Comments extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      [NPS]: false,
      [ELOGIOS]: false,
      [CRITICA]: false,
      [DETRATOR]: false,
      [PROMOTOR]: false,
      [NEUTRO]: false,
      npsTeste: [],
      record: {
        type: '-',
      },
      dataInitial: DATE_INITAL,
      dataFinal: DATE_FINAL,
      rowColor: '',
      tipoExibicaoQuesito: 1,
      idGrupo: 0,
      idUnidade: 0,
    };
    this.service = new Service();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const tokenLogin = localStorage.getItem('token');
      const decodeToken = jwt_decode(tokenLogin);
      const idUnidade =
        this.props.dataFilial.length > 0 ? this.props.dataFilial[0].id : 0;
      const idGrupo =
        this.props.companySelected.length > 0
          ? this.props.companySelected[0].id
          : 0;
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
        loading: true,
        idUnidade,
        idGrupo,
      });
      this.handleDate(idGrupo, idUnidade);
      this.loadTipoExibicaoQuesito();
    }
  }

  componentWillReceiveProps(props) {
    const idUnidade = props.dataFilial.length > 0 ? props.dataFilial[0].id : 0;
    const idGrupo =
      props.companySelected.length > 0 ? props.companySelected[0].id : 0;
    this.setState({
      idUnidade,
      idGrupo,
    });
    if (
      (idUnidade > 0 && idUnidade !== this.state.idUnidade) ||
      (idGrupo > 0 && idGrupo !== this.state.idGrupo)
    ) {
      this.handleDate(idGrupo, idUnidade);
    }
  }

  handleDate = async (idGrupo, idUnidade) => {
    this.setState({ loading: true });
    let result = await this.service.getDataFeedbacks(
      DATE_INITAL,
      DATE_FINAL,
      idUnidade > 0 ? idUnidade : this.props.dataFilial[0].id,
      idGrupo > 0 ? idGrupo : this.props.companySelected[0].id
    );

    if (result) {
      const data = result.object;

      const racionais =
        data &&
        data.filter(item => {
          return item.respostas.idSegmentoPergunta === 1;
        });
      // const emocionais = data && data.filter(_emocionais => {
      //   return _emocionais.respostas.idSegmentoPergunta === 2
      // })

      let selectedRowKeys = result.object.filter(item => item.verificado);
      selectedRowKeys = selectedRowKeys.map(item => item.id);

      this.setState({
        selectedRowKeys,
        data: result.object,
        dataInitial: DATE_INITAL,
        dataFinal: DATE_FINAL,
        racionais,
        loading: false,
      });
      this.formatDate();
    } else {
      this.props.history.push('/login');
    }
  };

  onChangeFeedbacks = async (value, dateString) => {
    this.setState({ dataInitial: dateString[0], dataFinal: dateString[1] });
  };

  onOk = value => {
    console.log('onOk: ', value);
  };

  onChange(checked, filterName) {
    this.setState({ [filterName]: checked });
  }

  applyFilter = async () => {
    this.setState({ loading: true });
    const { dataInitial, dataFinal } = this.state;

    if (dataInitial !== undefined && dataFinal !== undefined) {
      let result = await this.service.getDataFeedbacks(
        dataInitial,
        dataFinal,
        this.props.dataFilial[0].id,
        this.props.companySelected[0].id
      );

      result.object.forEach(item => {
        item.dataRespostas = moment(item.dataRespostas).format(
          'DD/MM/YYYY HH:mm'
        );
      });

      if (result !== undefined) {
        this.setState({ data: result.object });
      }
    }

    let data = this.state.data;

    if (this.state[NPS]) {
      data = data.filter(item => {
        const has_repostas = item.respostas.find(res => {
          return res.idPergunta === ID_PERGUNTA_NPS && res.valorChar !== null;
        });
        return has_repostas !== undefined;
      });
    }

    if (this.state[ELOGIOS]) {
      data = data.filter(item => {
        const has_repostas = item.respostas.find(res => {
          return (
            res.idPergunta === ID_PERGUNTA_ELOGIO && res.valorChar !== null
          );
        });
        return has_repostas !== undefined;
      });
    }

    if (this.state[CRITICA]) {
      data = data.filter(item => {
        const has_repostas = item.respostas.find(res => {
          return (
            res.idPergunta === ID_PERGUNTA_CRITICA && res.valorChar !== null
          );
        });
        return has_repostas !== undefined;
      });
    }

    if (this.state[DETRATOR]) {
      data = data.filter(item => {
        return item.nps >= 0 && item.nps <= 6;
      });
    }

    if (this.state[NEUTRO]) {
      data = data.filter(item => {
        return item.nps >= 7 && item.nps <= 8;
      });
    }

    if (this.state[PROMOTOR]) {
      data = data.filter(item => {
        return item.nps >= 9 && item.nps <= 10;
      });
    }

    let selectedRowKeys = data.filter(item => item.verificado);
    selectedRowKeys = selectedRowKeys.map(item => item.id);

    this.setState({ dataTable: data, loading: false, selectedRowKeys });
  };

  clearFilter = () => {
    this.setState({
      nps: false,
      elogio: false,
      critica: false,
      detrator: false,
      promotor: false,
      neutro: false,
      loading: true,
      dataInitial: DATE_INITAL,
      dataFinal: DATE_FINAL,
    });

    this.handleDate();
    this.setState({ loading: false });
  };

  getType = record => {
    const { nps } = record;

    if (nps === null || (nps >= 0 && nps <= 6)) {
      return DETRATOR_DESC;
    }

    if (nps >= 7 && nps <= 8) {
      return NEUTRO_DESC;
    }

    return PROMOTOR_DESC;
  };

  getTypeColor = type => {
    if (type === DETRATOR_DESC) {
      return '#f5222d';
    }
    if (type === NEUTRO_DESC) {
      return '#faad14';
    }
    if (type === PROMOTOR_DESC) {
      return '#52c41a';
    }
    return '#eee';
  };

  formatDate = () => {
    let dataTable = this.state.data;
    dataTable.forEach(item => {
      item.dataRespostas = moment(item.dataRespostas).format(
        'DD/MM/YYYY HH:mm'
      );
    });
    this.setState({ dataTable });
  };

  selectRow = record => {
    if (record) {
      this.setState({ rowColor: '#f5222d' });
    }

    const racionais = record.respostas.filter(item => {
      return item.idSegmentoPergunta === 1;
    });

    const racionaisTitles = racionais.map(item => {
      return { title: item.descricaoPergunta, description: item.valorInteiro };
    });

    const emocionais = record.respostas.filter(item => {
      return item.idSegmentoPergunta === 2;
    });

    const emocionaisTitles = emocionais.map(item => {
      return { title: item.descricaoPergunta, description: item.valorInteiro };
    });

    const outros = record.respostas.filter(item => {
      return item.idSegmentoPergunta === 5;
    });

    const outrosTitles = outros.map(item => {
      return {
        title: item.descricaoPergunta,
        description: item.descricaoSubPergunta,
      };
    });

    const nps = record.respostas.filter(item => {
      return item.idSegmentoPergunta === 3;
    });

    const elogios = record.respostas.filter(item => {
      return item.idPergunta === 86 && item.valorChar !== null;
    });

    const criticas = record.respostas.filter(item => {
      return item.idPergunta === 87 && item.valorChar !== null;
    });

    record['type'] = this.getType(record);
    this.setState({
      racionaisTitles,
      emocionaisTitles,
      outrosTitles,
      npsTeste: nps,
      elogios,
      criticas,
      record,
    });
  };

  checkAnswers = async selectRow => {
    message.loading('Processando', 1, () => {
      message.success(`Operação realizada com sucesso`, 1);
    });
    let selected = selectRow.map(i => i.id);
    let add = selected.filter(v => !this.state.selectedRowKeys.includes(v));
    let del = this.state.selectedRowKeys.filter(v => !selected.includes(v));
    add.forEach(async a => {
      await this.service.checkAnswers(a);
    });
    del.forEach(async d => {
      await this.service.checkAnswers(d);
    });

    this.props.setChecketedRow(selectRow);

    this.setState({
      selectedRowKeys: selected,
    });
  };

  exists(value, vitem) {
    for (let item of value) {
      if (item.id === vitem) {
        return true;
      }
    }
    return false;
  }

  onClickRow = record => {
    return {
      onClick: (event, index) => {
        this.selectRow(record, record, index, event);
        this.setState({
          rowId: record.id,
        });
      },
    };
  };
  setRowClassName = record => {
    return record.id === this.state.rowId ? 'clickRowStyl' : 'rowComment';
  };

  makeWhatsappUrl = phone => {
    let fullPhoneNumber = `55${phone.replace(/[^0-9]+/g, '')}`;

    return `https://wa.me/${fullPhoneNumber}`;
  };

  loadTipoExibicaoQuesito = async () => {
    let idEmpresa = 0;
    if (this.props.companySelected && this.props.companySelected.length > 0) {
      idEmpresa = this.props.companySelected[0].id;
    }
    const result = await this.service.consultarTipoExibicao(idEmpresa);
    this.setState({
      tipoExibicaoQuesito: result.object,
    });
  };

  render() {
    const { dataInitial, dataFinal } = this.state;

    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    const columns = [
      {
        title: 'Data',
        dataIndex: 'dataRespostas',
        sorter: (a, b) => new Date(a.dataRespostas) - new Date(b.dataRespostas),
      },
      {
        title: 'Nome',
        dataIndex: 'nome',
        render: text => (text === '' ? 'Anônimo' : <a href="#">{text}</a>),
      },
      {
        title: 'Tel',
        dataIndex: 'celular',
        key: '3',
        render: text =>
          text !== '' ? (
            <a
              href={this.makeWhatsappUrl(text)}
              class="linkwhatsapp"
              target="_blank"
            >
              {text}
            </a>
          ) : (
            'Anônimo'
          ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: '4',
        render: text => (text === '' ? 'Anônimo' : text),
      },
      {
        title: this.props.filialId == 999999 ? 'Unidade' : '',
        dataIndex: this.props.filialId == 999999 ? 'nomeFilial' : '',
        key: '5',
        render: text => (this.props.filialId == 999999 ? text : ''),
      },
    ];

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: async (selectedRowKeys, selectedRows) => {
        this.checkAnswers(selectedRows);
        this.setState({ selectedRows: selectedRows });
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const { RangePicker } = DatePicker;

    const genExtra = value =>
      value && value.length > 0 && value[0].valorChar !== null ? (
        <Badge count={1} style={{ backgroundColor: '#52c41a' }} />
      ) : (
        <Badge count={0} showZero />
      );

    return (
      <Layout>
        <Sidebar
          dataSidebar={homeClient}
          routes={routesClient}
          current="dashboard"
        />
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            <Row className="nopadding-col">
              <Col md={{ span: 16 }} xs={{ span: 24 }}>
                <Breadcrumb pages={[{ title: 'Avaliações' }]} />
              </Col>
            </Row>
            <Row style={{ backgroundColor: '#f0f2f5' }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                style={{ margin: 20 }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <h1>Por comentários</h1>
                  <Row style={{ display: 'flex', flexDirection: 'row' }}>
                    <Switch
                      checked={this.state.elogio}
                      onChange={e => this.onChange(e, ELOGIOS)}
                      style={{ marginRight: 10 }}
                    />
                    <p>Elogios / Sugestões / Comentários</p>
                  </Row>
                  <Row style={{ display: 'flex', flexDirection: 'row' }}>
                    <Switch
                      checked={this.state.critica}
                      onChange={e => this.onChange(e, CRITICA)}
                      style={{ marginRight: 10 }}
                    />
                    <p>Críticas / Reclamações</p>
                  </Row>
                </Row>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                style={{ margin: 20 }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <h1>Por tipo</h1>
                  <Row style={{ display: 'flex', flexDirection: 'row' }}>
                    <Switch
                      checked={this.state.detrator}
                      onChange={e => this.onChange(e, DETRATOR)}
                      style={{ marginRight: 10 }}
                    />
                    <p>Detrator</p>
                  </Row>
                  <Row style={{ display: 'flex', flexDirection: 'row' }}>
                    <Switch
                      checked={this.state.neutro}
                      onChange={e => this.onChange(e, NEUTRO)}
                      style={{ marginRight: 10 }}
                    />
                    <p>Neutro</p>
                  </Row>
                  <Row style={{ display: 'flex', flexDirection: 'row' }}>
                    <Switch
                      checked={this.state.promotor}
                      onChange={e => this.onChange(e, PROMOTOR)}
                      style={{ marginRight: 10 }}
                    />
                    <p>Promotor</p>
                  </Row>
                </Row>
              </Col>
              <Col
                xs={{ span: 20 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                style={{ margin: 20 }}
              >
                <h1>Período</h1>
                {this.state.dataInitial !== undefined ? (
                  <RangePicker
                    defaultValue={[
                      moment(dataInitial, DATE_FORMAT),
                      moment(dataFinal, DATE_FORMAT),
                    ]}
                    format={DATE_FORMAT}
                    placeholder={['Data inicial', 'Data final']}
                    onChange={this.onChangeFeedbacks}
                    onOk={this.onOk}
                  />
                ) : null}
                <Row style={{ marginTop: 20 }}>
                  <Button type="primary" onClick={this.applyFilter}>
                    Aplicar
                  </Button>
                  <Button
                    type="danger"
                    onClick={this.clearFilter}
                    style={{ marginLeft: 20 }}
                  >
                    Limpar
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 20 }} md={{ span: 20 }} lg={{ span: 14 }}>
                <Table
                  rowSelection={rowSelection}
                  hideDefaultSelections={true}
                  selections={true}
                  rowKey={item => item.id}
                  columns={columns}
                  loading={this.state.loading}
                  dataSource={this.state.dataTable && this.state.dataTable}
                  pagination={{ defaultPageSize: 10 }}
                  size="middle"
                  scroll={{ x: 500 }}
                  rowClassName={this.setRowClassName}
                  onRow={this.onClickRow}
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 10 }}
                style={{
                  backgroundColor: '#FFF',
                  border: '1px solid rgba(0, 0, 0, 0.10)',
                  padding: '1rem',
                  background: 'rgba(213, 237, 250, 0.3)',
                  marginTop: 10,
                }}
              >
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                    <Row
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col>
                        <h1>Racionais</h1>
                        <List
                          itemLayout="horizontal"
                          dataSource={this.state.racionaisTitles}
                          renderItem={item => (
                            <List.Item>
                              <List.Item.Meta
                                title={item.title}
                                description={`Avaliação: ${item.description}`}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                      <Col>
                        <h1>Emocionais</h1>
                        <List
                          itemLayout="horizontal"
                          dataSource={this.state.emocionaisTitles}
                          renderItem={item => (
                            <List.Item>
                              <List.Item.Meta
                                title={item.title}
                                description={`Avaliação: ${item.description}`}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>

                      <Col>
                        <h1>Outros</h1>
                        <List
                          itemLayout="horizontal"
                          dataSource={this.state.outrosTitles}
                          renderItem={item => (
                            <List.Item>
                              <List.Item.Meta
                                title={item.title}
                                description={`${item.description}`}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={{ span: 12, offset: 10 }}
                    md={{ span: 6, offset: 0 }}
                    lg={{ span: 4, offset: 3 }}
                    style={{ marginTop: 10 }}
                  >
                    <Row
                      style={{ marginBottom: 10, textAlign: '-webkit-center' }}
                    >
                      <h1 style={{ fontSize: '0.8rem' }}>
                        Índice Blue Individual
                      </h1>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#0a2851',
                          borderRadius: '50%',
                          height: 80,
                          width: 80,
                          marginTop: 30,
                        }}
                      >
                        <h1 style={{ color: '#FFF', fontSize: 20 }}>
                          {this.state.record &&
                          this.state.record.indiceBlue != undefined &&
                          this.state.record.indiceBlue >= 0
                            ? this.state.record.indiceBlue.toFixed()
                            : '--'}
                        </h1>
                        {this.state.record &&
                        this.state.record.indiceBlue != undefined &&
                        this.state.record.indiceBlue >= 0 ? (
                          <p style={{ color: '#FFF', fontSize: 10 }}>0 -100</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <div style={{ marginTop: 40 }}>
                          <h3 style={{ fontSize: '0.8rem' }}>
                            Nota NPS Individual{' '}
                          </h3>
                        </div>
                        <Card
                          style={{
                            width: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: '#f0f2f5',
                            transform: 'translateX(-1.05rem)',
                          }}
                        >
                          <h1
                            style={{
                              color: 'blue',
                              fontSize: 20,
                              textAlign: 'center',
                            }}
                          >
                            {this.state.record && this.state.record.nps !== null
                              ? this.state.record.nps
                              : 0}
                          </h1>
                          <p
                            style={{
                              color: 'blue',
                              fontSize: 10,
                              textAlign: 'center',
                            }}
                          >
                            0 -10
                          </p>
                          <div
                            style={{
                              backgroundColor: this.getTypeColor(
                                this.state.record.type
                              ),
                              width: 100,
                            }}
                          >
                            <p
                              style={{
                                color: '#FFF',
                                fontSize: 14,
                                textAlign: 'center',
                              }}
                            >
                              {this.state.record.type}
                            </p>
                          </div>
                        </Card>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Col style={{ marginTop: 46 }}>
                  <Collapse accordion style={{ marginBottom: 50 }}>
                    <Panel
                      header="ELOGIOS / SUGESTÕES / COMENTÁRIOS"
                      key="2"
                      extra={genExtra(this.state.elogios && this.state.elogios)}
                      style={{
                        color: '#000',
                        '.ant-collapse-content': {
                          backgroundColor: '#000',
                        },
                      }}
                    >
                      {this.state.elogios && this.state.elogios.length > 0 ? (
                        <p>{this.state.elogios[0].valorChar}</p>
                      ) : null}
                    </Panel>
                    <Panel
                      header="CRÍTICAS / RECLAMAÇÕES"
                      key="3"
                      extra={genExtra(
                        this.state.criticas && this.state.criticas
                      )}
                    >
                      {this.state.criticas && this.state.criticas.length > 0 ? (
                        <p>{this.state.criticas[0].valorChar}</p>
                      ) : null}
                    </Panel>
                  </Collapse>
                </Col>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

Comments.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData, setChecketedRow }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
  clientFilial: store.userData.clientFilial,
  filialId: store.userData.filialId,
  rowSelected: store.userData.rowSelected,
  companySelected: store.userData.companySelected,
  dataFilial: store.userData.dataFilial,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Comments));
